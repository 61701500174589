@import url('https://fonts.googleapis.com/css2?family=Gemunu+Libre:wght@200;300;400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Gemunu+Libre:wght@200;300;400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --primary: #203672;
  --secondary: #00ecb7;
  --dark-green: #0ad09b;
  --light-green: #38ffcb;
  --dark: #222d35;
  --white: #fff;
  --black: #000;
  --dark-grey: #5f5f5f;
  --grey: #a2a2a2;
  --light-grey: #f3f3f3;
  --bg: #000;
  --text: #7b7b7b;
  --bg-transparent: #00000060;
  --error: #ff0000;
  --success: #62df54;
  --fjalla: 'Fjalla One', sans-serif;
  --max-app-width: 1200px;
  --v-margin: 50px;
  --h-margin: 50px;
  --v-margin-mobile: 10px;
}
* {
  font-family: Poppins, sans-serif;
}

html {
  scroll-behavior: smooth;
}
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--dark);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  font-family: var(--fjalla);
}
p {
  font-size: 16px;
  color: var(--text);
}
.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: var(--bg);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.centered-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.buttonied,
.buttonied:visited,
.buttonied:hover {
  transition: all 300ms ease;
  background-size: 500%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 2.5em;
  background-image: linear-gradient(to left, var(--dark-green), var(--light-green), var(--dark-green));
  padding: 0 20px;
  border-radius: 6px;
  background-position: 0%;
  color: var(--dark) !important;
  animation: shine 3s linear infinite alternate;
}
@keyframes shine {
  0% {
    background-position: 0%;
  }
  50% {
    background-position: 100%;
  }
  100% {
    background-position: 0%;
  }
}
